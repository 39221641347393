import React from 'react'

// Displays an upload request status with a different coloured background
// depending on the status. The status is equal to this.props.children. Only
// the following are valid values for this.props.children:
// * Pending
// * Completed
// * Rejected
//
// Anything else will cause an error message to be rendered instead.
class UploadRequestStatus extends React.Component {
  render() {
    switch(this.props.children) {
      case 'Pending':
        var backgroundColour = '#00B3EB';
        break;
      case 'Completed':
        backgroundColour = '#76C557';
        break;
      case 'Rejected':
        backgroundColour = '#C55656';
        break;
      default:
        return(<p>A system error has occured.</p>);
    }

    return(
      <React.Fragment>
        <span
          style={
            {backgroundColor: backgroundColour,
             padding: '9px',
             borderRadius: '11px'}
          }>
          {this.props.children}
        </span>
      </React.Fragment>
    );
  }
}

export default UploadRequestStatus
