import React from "react"
import Spinner from "./spinner"
import { Auth } from "aws-amplify"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import Tooltip from './tooltip'
import UploadRequestStatus from './uploadrequeststatus'
import EmptyUploadsAlert from './emptyuploadsalert'
import { formatCreationDate } from '../helpers/helpers'

class UploadRequestsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loaded: false,
      items: []
    };
  }
  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      Auth.currentSession()
        .then(async data => {
          var res = await fetch(
            `${process.env.GATSBY_API_URL}/requests?username=${this.props.authenticatedUsername}`,
            {
              method: 'GET',
              mode: 'cors',
              headers: new Headers({
                'Authorization': 'Bearer ' + data.idToken.jwtToken
              })
            }
          );

          const result = await res.json();

          this.setState({
            error: null,
            loaded: true,
            items: result
          });
        })
        .catch(err => console.log(err));
    }
  }

  render() {
    const { error, loaded, items } = this.state;

    if (error) {
      return <div>Error</div>;
    } else if (!loaded) {
      return <Spinner />;
    } else {
      var count = items.length;

      if (count === 0) {
	return(<EmptyUploadsAlert />);
      } else {
	return(
	  <React.Fragment>
	    <table>
	      <thead>
		<tr>
		  <th>Summary <Tooltip>
                    Your upload request details.
                    </Tooltip>
                  </th>
		  <th>Submitted</th>
		  <th>
                    Status <Tooltip>
                      Completed: your request has been approved and fulfilled; you should now see the uploaded files on the vault's details page.
                      <p />
                      Pending: awaiting approval from an admin.
                      <p />
                      Rejected: your request has been rejected (we will contact you about this).
                    </Tooltip>
                  </th>
		</tr>
	      </thead>
	      <tbody>
		{
		  items.map((request, index) => (
		    <tr key={index}>
		      <td key="0">
                        {request.sourceFileserver}:{request.sourceDirectory}&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faLongArrowAltRight} />&nbsp;&nbsp;&nbsp;
                        {request.targetArchiveTitle}
                      </td>
                      <td key="1">{formatCreationDate(request.creationDateSeconds)}</td>
                      <td key="2"><UploadRequestStatus>{request.state}</UploadRequestStatus></td>
		    </tr>
		  ))
		}
	      </tbody>
	    </table>
	  </React.Fragment>);
      }
    }
  }
}

export default UploadRequestsTable
