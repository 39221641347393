import React from "react"
import UploadRequestsTable from "./uploadrequeststable"
import { authenticatedLoginContext } from './authenticatedLoginContext'

class UploadRequestsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticatedUsername: 'unidentified human'
    };
  }

  componentDidMount() {
    this.setState({
      authenticatedUsername: this.context.username
    });
  }

  render() {
    if (this.state.authenticatedUser === 'unidentified human') {
      return(<p>Loading...</p>);
    } else {
      return(
        <React.Fragment>
          <h3>Upload requests</h3>
          <br />
          <p>Your upload requests are listed below.</p>
          <p>Upload requests for any vaults you own will be listed here, even if you did you not submit the request personally.</p>
          <p>We aim to respond to any new upload requests within six days.</p>
          <UploadRequestsTable authenticatedUsername={this.state.authenticatedUsername} />
        </React.Fragment>);
    }
  }
}

UploadRequestsPage.contextType = authenticatedLoginContext;

export default UploadRequestsPage
